<script>
  export default {
    props: {
      google: {
        type: Object,
        required: true,
      },
      map: {
        type: Object,
        required: true,
      },
      marker: {
        type: Object,
        required: true,
      },
    },

    mounted () {
      const { Marker } = this.google.maps
      const marker = new Marker({
        position: this.marker.position,
        marker: this.marker,
        map: this.map,
        title: this.marker.title,
        id: this.marker.id,
        icon: this.marker.icon,
      })
      this.google.maps.event.addListener(marker, 'click', () => {
        this.$router.push({
          name: 'Dispositivo',
          params: { codigo: marker.id },
        })
      })
    },
    render () {
      return null
    },
  }

</script>
