<template>
  <div>
    <div
      class="mapa"
      ref="mapa"
    />
    <template v-if="Boolean(google) && Boolean(map)">
      <slot
        :google="google"
        :map="map"
      />
    </template>
    <template>
      <v-card
        class="margin_card"
        max-width="344"
        outlined
        id="leyenda"
      >
        <v-card-title class="text-center">
          Selecciona un dispositivo
        </v-card-title>
      </v-card>
    </template>
  </div>
</template>

<script>

  import GoogleMapsApiLoader from 'google-maps-api-loader'

  export default {
    name: 'Dispositivos',
    props: {
      configMapa: Object,
      apiKey: String,
      marcadores: Array,
    },

    data () {
      return {
        google: null,
        map: null,
        initialZoom: false,
      }
    },
    watch: {
      map: function () {
        const bounds = new this.google.maps.LatLngBounds()
        this.marcadores.forEach((marcador) => bounds.extend(marcador.position))
        this.map.fitBounds(bounds)
        if (this.marcadores.length <= 1) {
          this.map.setZoom(20)
        }
      },
    },

    async mounted () {
      const googleMapApi = await GoogleMapsApiLoader({
        apiKey: this.apiKey,
      })
      this.google = googleMapApi
      this.initializeMap()
    },

    methods: {
      initializeMap () {
        const mapContainer = this.$refs.mapa
        this.map = new this.google.maps.Map(mapContainer, this.configMapa)
        // this.google.maps.event.addListener(this.map, 'zoom_changed', () => {
        //   // console.log(this.map.getZoom(), this.initialZoom)
        //   // const zoomChangeBoundsListener = this.google.maps.event.addListener(this.map, 'bounds_changed', (event) => {
        //   //   if (this.map.getZoom() > 4 && this.initialZoom) {
        //   //     this.map.setZoom(4)
        //   //     this.initialZoom = false
        //   //     this.google.maps.event.removeListener(zoomChangeBoundsListener)
        //   //   }
        //   // })
        // })
        const legend = document.querySelector('#leyenda')
        this.map.controls[this.google.maps.ControlPosition.TOP_CENTER].push(legend)
      },
    },
  }
</script>

<style scoped>
  .mapa {
    width: 100%;
    min-height: 100%;
  }
  .margin_card {
    margin-top: 0.5em;
  }
</style>
