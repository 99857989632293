export const configMapa = {
  clickableIcons: false,
  streetViewControl: false,
  panControlOptions: false,
  gestureHandling: 'cooperative',
  mapTypeControl: false,
  zoom: 2,
  minZoom: 5,
  maxZoom: 19,
}
