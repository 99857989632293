<template>
  <mis-dispositivos
    v-if="dispositivos.length !== 0"
    :config-mapa="configMapa"
    api-key="AIzaSyBCLXkXXqi4H-xpzhu4NgvJGhQ5fDpUvHo"
    style="height: calc(100vh - 75px - 86px)"
    :marcadores="marcadores"
  >
    <template slot-scope="{ google, map }">
      <mapa-marcadores
        :google="google"
        :key="marcador.id"
        :map="map"
        :marker="marcador"
        v-for="marcador in marcadores"
      />
    </template>
  </mis-dispositivos>
</template>

<script>

  import Dispositivos from '../../../components/maps/Dispositivos'
  import Marcadores from '../../../components/maps/Marcadores'
  import { configMapa } from '../../../plugins/maps'
  import { mapActions, mapState } from 'vuex'

  export default {
    name: 'DashboardMisDispositivos',
    components: {
      'mis-dispositivos': Dispositivos,
      'mapa-marcadores': Marcadores,
    },
    data () {
      return {}
    },
    methods: {
      ...mapActions('iot', ['fetchDispositivos']),
      calcularIconoMarcador (estado) {
        switch (estado) {
          case 'NORMAL':
            return 'https://maps.google.com/mapfiles/ms/icons/green-dot.png'
          case 'MEDIO':
            return 'https://maps.google.com/mapfiles/ms/icons/orange-dot.png'
          case 'ALTO':
            return 'https://maps.google.com/mapfiles/ms/icons/red-dot.png'
        }
      },
    },
    async mounted () {
      await this.fetchDispositivos()
    },
    computed: {
      ...mapState('iot', ['dispositivos']),
      configMapa () {
        return {
          ...configMapa,
          center: this.mapCenter,
        }
      },
      marcadores () {
        return this.dispositivos.map(({ codigo, latitud, longitud, nombre, estado }) => {
          return {
            id: codigo,
            title: nombre,
            icon: this.calcularIconoMarcador(estado),
            position: {
              lat: parseFloat(latitud),
              lng: parseFloat(longitud),
            },
          }
        })
      },
      mapCenter () {
        return this.dispositivos.length === 0 ? {
          lat: 19.15,
          lng: 18.5,
        } : this.marcadores[0].position
      },
    },
  }
</script>
